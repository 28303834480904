<template>
  <div class="bars-page container">
    <template v-if="dev" >
      <div style="margin-bottom: 1rem">
        <p class="has-text-centered">{{ $t("user.login") }}: {{ grafanaLogin }}</p>
        <p class="has-text-centered">{{ $t("user.password") }}: {{ grafanaPassword }}</p>
      </div>
    </template>
    <div class="card" style="padding-bottom: 30px;">
      <div class="card-header" ><h5>{{ $t("grafana.requests") }}</h5></div>
      <div class="card-body">
        <p>
          {{ $t("grafana.requests_text") }}
        </p>

        <br>
        <p>
          <a :href="this.grafanaQueries" target="_blank" class="btn btn-primary">{{ $t("grafana.redirect") }}</a>
        </p>
        <p><small>{{$t('grafana.select_oauth')}}</small></p>
      </div>
    </div>


    <div class="card" style="padding-bottom: 30px;">
      <div class="card-header" ><h5>{{ $t("grafana.data_bases") }}</h5></div>
      <div class="card-body">
        <p>
          {{ $t("grafana.data_bases_text") }}
        </p>

        <br>
        <p>
          <a @click="databasesRedirect" target="_blank" class="btn btn-primary">{{ $t("grafana.redirect") }}</a>
        </p>
      </div>
    </div>









    <div class="card" style="padding-bottom: 30px;">
      <div class="card-header"><h5>{{ $t("grafana.software_server") }}</h5></div>
      <div class="card-body">
        <p>
          {{ $t("grafana.software_text") }}
        </p>
        <br>
        <p>
          <a :href="this.grafanaHard" target="_blank" class="btn btn-primary">{{ $t("grafana.redirect") }}</a>
        </p>
        <p v-if="dev" style="margin-top: 1rem;">
          <small>{{ $t("grafana.demo_note") }}</small>
        </p>
      </div>
    </div>
    <div class="card" style="padding-bottom: 30px;">
      <div class="card-header"><h5>{{ $t("grafana.farm") }}</h5></div>

      <div class="card-body">
        <p>
          {{ $t("grafana.farm_note") }}
        </p>
        <br>
        <p>
          <a :href="this.grafanaFarm" target="_blank" class="btn btn-primary">{{ $t("grafana.redirect") }}</a>
        </p>
      </div>
    </div>
    <div class="card" style="padding-bottom: 30px;">
      <div class="card-header"><h5>{{ $t("stats.androidDevices") }}</h5></div>

      <div class="card-body">
        <p>
          {{ $t("stats.description") }}
        </p>
        <br>
        <p>
          <router-link :to="{name: 'androidDevices'}" class="btn btn-primary">{{$t('grafana.redirect')}}</router-link>
        </p>
      </div>
    </div>


  </div>
</template>

<script>
import moment from "moment";
import filters from "@/mixins/filters";
import BaseSourceDate from "./BaseSourceDate.vue";
import env from "@/configs/env.json";
export default {
  name: "SearchRequests",
  components: {
    // ParamsChart,
    // PerformanceChart,
    BaseSourceDate,
    // DateFilter,
    // SoftwareServices,
    // UserRequests,
  },
  mixins: [filters],
  data() {
    return {
      loaded: false,
      params: [],
      sourceResults: [0, "online", "cache"],
      payload: {
        filters: {
          users_ids: [],
          created_from: moment().subtract(1, "month").toDate(),
          created_to: moment().toDate(),
          param: 0,
        },
        analysis: {
          has_results: 0,
          service: 0,
          source_results: 0,
        },
      },
      dev: env.dev,
      grafanaHard: '',
      grafanaQueries: '',
      grafanaFarm: env.grafanaFarm,
      grafanaLogin: env.grafanaLogin,
      grafanaPassword: env.grafanaPassword,
    };
  },
  methods: {
    databasesRedirect(){
      this.$router.push({name: 'DatabasesStats'})
    },
    ///todo check for need
    loadSearchRequests() {
      this.loaded = false;
      this.$store
        .dispatch("stats/getSearchRequests", this.processingPayload)
        .then(() => {
          this.loaded = true;
        });
    },
    loadSearchParams() {
      if (!this.searchParams.length) {
        this.$store.dispatch("contacts/getSearchParams");
      }
    },
    setUsers(usersIds) {
      this.payload.filters.users_ids = usersIds;
    },
  },
  computed: {
    chartData() {
      const data = {
        labels: [],
        datasets: [{ label: this.$t("search.requests"), backgroundColor: "#055A6B", data: [] }],
      };
      this.searchRequests.forEach((element) => {
        data.labels.push(`${element.login}`),
          data.datasets[0]["data"].push(element.total);
      });
      return data;
    },
    chartOptions() {
      return { responsive: true, legend: "users" };
    },
    processingPayload() {
      let payload = JSON.parse(JSON.stringify(this.payload));
      if (typeof this.payload.filters !== "undefined") {
        if (
          typeof this.payload.filters.param !== "undefined" &&
          [null, 0].includes(this.payload.filters.param)
        ) {
          delete payload.filters.param;
        }
        if (typeof this.payload.filters.created_from !== "undefined") {
          if (this.payload.filters.created_from !== null) {
            payload.filters.created_from = parseInt(
              moment(this.payload.filters.created_from).format("X")
            );
          } else {
            delete payload.filters.created_from;
          }
        }
        if (typeof this.payload.filters.created_to !== "undefined") {
          if (this.payload.filters.created_to !== null) {
            payload.filters.created_to = parseInt(
              moment(this.payload.filters.created_to).format("X")
            );
          } else {
            delete payload.filters.created_to;
          }
        }
        if (
          typeof this.payload.filters.users_ids !== "undefined" &&
          this.payload.filters.users_ids.length === 0
        ) {
          delete payload.filters.users_ids;
        }
        if (Object.keys(this.payload.filters).length === 0) {
          delete payload.filters;
        }
      }
      if (typeof this.payload.analysis !== "undefined") {
        if (
          typeof this.payload.analysis.has_results !== "undefined" &&
          this.payload.analysis.has_results === 0
        ) {
          delete payload.analysis.has_results;
        }
        if (
          typeof this.payload.analysis.service !== "undefined" &&
          this.payload.analysis.service === 0
        ) {
          delete payload.analysis.service;
        }
        if (
          typeof this.payload.analysis.source_results !== "undefined" &&
          this.payload.analysis.source_results === 0
        ) {
          delete payload.analysis.source_results;
        }
        if (Object.keys(this.payload.analysis).length === 0) {
          delete payload.analysis;
        }
      }
      return payload;
    },
  },
  mounted() {
    let host = '';
    if(location.hostname.includes('web')) {
      host = location.hostname.replace('web.', '')
      let domain = `https://grafana.stats.${host}`;
      env.grafanaHard = `${domain}${env.grafanaHard}`;
      env.grafanaQueries = `${domain}${env.grafanaQueries}`;
      env.grafanaFarm = `${domain}${env.grafanaFarm}`;
    }
    this.grafanaHard = env.grafanaHard;
    this.grafanaQueries = env.grafanaQueries;
    this.grafanaFarm = env.grafanaFarm;


  },
  watch: {
    "payload.filters.created_to": function () {
      this.loadSearchRequests();
    },
    "payload.filters.created_from": function () {
      this.loadSearchRequests();
    },
  },
};
</script>

<style  lang="scss">
.buttContainer {
  display: flex;
}

.mt20 {
  margin-top: 20px;
}

.card-body {
  padding-left: 15px;
  padding-right: 15px;
}
.has-text-centered {
  text-align: center !important;
}
</style>
<style lang="scss" scoped>
.header {
  background-color: $light;
  padding: 0.5em;
  color: white;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;

  .sortButt {
    text-align: center;
    width: 33%;
  }

  .sortButt:not(:last-child) {
    border-right: solid 1px white;
  }
}
</style>